import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";

var count = []

class IESADWinners extends Component {
  constructor(props){
    super(props);
    this.state = {
       winneryear:null,
    };

    this.setval = this.setval.bind(this)
  }

  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    var winyear = localStorage.getItem("winneryear")
    document.title="International English Speech Art and Drama Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/iesad_logo.png"
    link2.href="/assets/iesad_logo.png"
    this.setState({winneryear:winyear})
    this.props.setval({comp:"iesad"}) 
  }      
      
  render(){
    for(let i=1;i<=this.props.winnercount["iesad"][this.state.winneryear];i++){
      count[i] = i

    }
    return (
      <div>

      <div className="home-content-div" style={{textAlign:"justify",textJustify:"inter-word", padding:"4% 6%",fontFamily:"Courgette"}}>
        
        <h2 className='winners-title'>International English Speech Art and Drama Competition</h2>
        <h5 className='winners-title'>{this.state.winneryear+" Winners"}</h5>

        <div className={["winner-body"].join(" ")} >
         {
           count.map((item)=>{
            return  <img src={"../assets/iesad"+this.state.winneryear+"winner/"+parseInt(item)+".PNG"} className={["winner-card"].join(" ")} />
              
           })
         }  
        </div>
           
        <div style={{fontSize:"0.9rem",marginTop:"65px"}}>*The profiles on this page are contributed by contestants and the list is not exhausive.</div>
      </div>
      </div>
    );
  }
  }
  

export default IESADWinners;
