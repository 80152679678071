import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";
import PaypalCheckoutBtn from './elements/paypalcheckout';
import AwardCheckout from './elements/awardcheckout';
import laureate from '../../laureate';

const applyurl2 = "https://docs.google.com/forms/d/e/1FAIpQLScggG7aHFpiPwcCFr6gnwn4Oh5nsHpCFHN3BWgN5hCdrF5dbA/viewform"
class Awards extends Component {
  constructor(props){
    super(props);
    this.state = {
       itemType:"Please select your item",
       productDescription:null,
       awardPayment:0,
    };

    this.setval = this.setval.bind(this)
    this.clickapply = this.clickapply.bind(this)
  }

  clickapply(){
    window.open(applyurl2, '_blank', 'noopener,noreferrer');
}
  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    document.title="International Young Artist Open Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/iyao_logo.png"
    link2.href="/assets/iyao_logo.png"
    this.props.setval({comp:"iyao"}) 
  }      
      
  render(){
    return (
      <div><img src="/assets/award.png" alt="" width="100%" style={{borderRadius:"0px"}}/>
      <div className="home-content-div" style={{textAlign:"justify",textJustify:"inter-word", padding:"7%",fontFamily:"Courgette"}}>

        <h1 style={{marginBottom:"1vh"}}>Order Trophy and Certificate</h1>
        <br/><h5>
          Please select the item you want and pay online below. You may then proceed to entering your shipment information with the button below.
        </h5>
        <div style={{display:"flex",flexDirection:"column",alignItems:"left",padding:"5px",border:"1px solid white",background:"rgba(255,255,255,0.9)",paddingLeft:"5%",paddingRight:"5%",color:"#1c1f1d",borderRadius:"10px",marginTop:"4%"}}>
            
            <h2  style={{marginLeft:"1%",marginTop:"2%",marginBottom:"3%"}}>Pay now with Credit Card or PayPal 
              <p style={{fontSize:"0.56em",textAlign:"justify"}}>*Service charge may be applied. Please double check your amount in the paypal pop-up window. Remember to take a screenshot after successful payment. 
                If you forgot, you may take a screenshot of your payment receipt email from PayPal.</p></h2>
            
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {this.state.itemType}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                    <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Trophy, Certificate, Reference Letter AND CD Recording",productDescription:"IYAO Trophy, Certificate, CD Recording, Reference Letter and Shipment",awardPayment:"130"})
                      }}>{"Trophy, Certificate, Reference Letter AND CD Recording"}</Dropdown.Item>
                    <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Trophy, Certificate AND CD Recording",productDescription:"IYAO Trophy, Certificate, CD Recording and Shipment",awardPayment:"100"})
                      }}>{"Trophy, Certificate AND CD Recording"}</Dropdown.Item>
                       <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Trophy, Certificate AND Reference Letter",productDescription:"IYAO Trophy, Certificate, Reference Letter and Shipment",awardPayment:"100"})
                      }}>{"Trophy, Certificate AND Reference Letter"}</Dropdown.Item>
                    <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Trophy AND Certificate",productDescription:"IYAO Trophy, Certificate and Shipment",awardPayment:"85"})
                      }}>{"Trophy AND Certificate"}</Dropdown.Item>
                      <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Trophy AND CD Recording",productDescription:"IYAO Trophy, CD Recording and Shipment",awardPayment:"85"})
                      }}>{"Trophy AND CD Recording"}</Dropdown.Item>
                      <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Trophy ONLY",productDescription:"IYAO Trophy and Shipment",awardPayment:"65"})
                      }}>{"Trophy ONLY"}</Dropdown.Item>
                     <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Certificate ONLY",productDescription:"IYAO Certificate and Shipment",awardPayment:"25"})
                      }}>{"Certificate ONLY"}</Dropdown.Item>
                       <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"CD Recording ONLY",productDescription:"IYAO CD Recording and Shipment",awardPayment:"25"})
                      }}>{"CD Recording ONLY"}</Dropdown.Item>
                       {/* <Dropdown.Item onClick={(e)=>{
                        this.setState({itemType:"Testing",productDescription:"Testing",awardPayment:"0.01"})
                      }}>Test</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>

            <br></br>
                {
                  (this.state.itemType==="Trophy, Certificate, Reference Letter AND CD Recording") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Trophy, Certificate, Reference Letter, CD Recording and Shipement Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                {
                  (this.state.itemType==="Trophy, Certificate AND CD Recording") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Trophy, Certificate, CD Recording and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                 {
                  (this.state.itemType==="Trophy, Certificate AND Reference Letter") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Trophy, Certificate, Reference Letter and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                {
                  (this.state.itemType==="Trophy AND Certificate") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Trophy, Certificate and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                {
                  (this.state.itemType==="Trophy AND CD Recording") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Trophy, CD Recording and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                {
                  (this.state.itemType==="Certificate AND CD Recording") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Certificate, CD Recording and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                {
                  (this.state.itemType==="Trophy ONLY") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Trophy and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }

                {
                  (this.state.itemType==="Certificate ONLY") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Certificate and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
                {
                  (this.state.itemType==="CD Recording ONLY") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"CD Recording and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }

                {
                  (this.state.itemType==="Testing") && 
                  <h3 style={{marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
                    <i>{"Certificate and Shipment Fee : USD "+this.state.awardPayment}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                }
              

            <div style={{marginTop:"3%",marginLeft:"10%",marginRight:"10%"}}>
         {/* { <PaypalCheckoutButton  product={{description:"IYAO",price:52}}/>} */}
         <AwardCheckout {...this.state}/>
          </div>

          
          </div>


          <div style={{marginTop:"4%"}}>
          <h4 className="apply-body-font" style={{fontFamily:"Courgette",fontWeight:"bolder",fontSize:"2rem"}}>If you have your payment receipt, you may now click the button below and fill in your shipment information.</h4>
          </div>
          
          <a onClick={(e)=>{this.clickapply()}}><button id="goldbutton-apply" style={{fontSize:"160%",width:"50%"}} >
          Apply for Award</button></a>
         

        {/* PAYPAL END */}


        <h1 style={{marginTop:"0%",marginBottom:"1vh"}}>Awards</h1>
        <br/><h5>For teachers:</h5>

        <ul>
          <li>
            <h5><b>Excellent Mentorship Award</b> - Teachers of Champion, Second Prize and Third Prize winners</h5>
          </li>
          <li>
          <h5> <b>Active Music Educator Award</b> - Any teacher who recommends four students or above across any groups</h5>
          </li>
        </ul>
        
        <br/>
        
        <h5>For centres and music institutions:</h5>

        <ul>
          <li>
            <h5><b>Active Music Institution Award</b> - Any organization which recommended 6 contestants or above across any groups</h5>
          </li>
          <li>
          <h5> <b>Elite Music Institution Award</b> - Any organization with 4 or more associated contestants receiving Champion, 2nd, 3rd or 4th Prize.</h5>
          </li>
        </ul>
        
        <br/>

        
        <h5>For students:</h5>

        <ul>
          <li>
          <h5><b>Champion to 4th place winners</b> - Contestants will receive a reference letter and be invited to have the submission recorded into CD.</h5>
          </li>
          <li>
          <h5><b>Gold Awards</b> - Contestants with marks above 85</h5>
          </li>
          <li>
          <h5><b>Silver Awards</b> - Contestants with marks above 75</h5>
          </li>
          <li>
          <h5><b>Bronze Awards</b> - Contestants with marks above 65</h5>
          </li>
          <li>
          <h5><b>Special Awards</b> - Awards given for specific repertoire such as:</h5>
              <ul>
              <li>
                <h6>Best Sonata Performance Award</h6>
                </li>
                <li>
                <h6>Best Sonatina Performance Award</h6>
                </li>
                <li>
                <h6>Best Baroque Music Performance Award</h6>
                </li>
                <li>
                <h6>Best Classical Music Performance Award</h6>
                </li>
                <li>
                <h6>Best Romantic Music Performance Award</h6>
                </li>
                <li>
                <h6>Best Modern Music Performance Award</h6>
                </li>
                <li>
                <h6>Best Chopin Etude Performance Award</h6>
                </li>
                <li>
                <h6>Best Chopin Nocturne Performance Award</h6>
                </li>
                <li>
                <h6>Best Chopin Waltz Performance Award</h6>
                </li>
              </ul>
          </li>
          <br/>
          <li>
          <h5>Teachers of our winners will be invited to the Outstanding Music Teacher List, and be invited for an interview to be featured on our magazine. Winners will also be invited to feature in our magazine and displayed in winner posters on our social media.</h5>
          </li>
          <li>
          <h5>Prizes will be in the form of e-certificates.</h5>
          </li>
        </ul>
        
        <br/>


        <div style={{width:"100%",overflowX:"hidden",overflowY:"hidden",msOverflowStyle:"none",scrollbarWidth:"none"}} >
          <table style={{width:"100%"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Places</th>
            </tr>
            <tr>
              <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>Champion</td>
              <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>Second Prize</td>
              <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>Third Prize</td>
              <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>Fourth Place</td>
            </tr>
          </table>


          <br></br>

          <table style={{width:"100%"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Class Awards</th>
            </tr>
            <tr style={{}}>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%",borderTop:"1px solid white"}}>Gold Award</td>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%",borderTop:"1px solid white"}}>Silver Award</td>
              <td style={{border:"1px solid black",textAlign:"center",width:"33%",borderTop:"1px solid white"}}>Bronze Award</td>
            </tr>
          </table>

          
          <br></br>

         

          <table style={{minWidth:"100%"}}>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Teacher Awards</th>
            </tr>
            <tr>
              <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>Active Muisc Educator Award</td>
              <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>Excellent Mentorship Award</td>
            </tr>
          </table>

        </div>

        <br/><br/>
        <h4 className="apply-body-font">With full mark as 100, contestants will be evaluated with (but not limited to) the following<sup>*</sup>:</h4>
        <br/>

        <div style={{width:"100%",overflowX:"hidden",overflowY:"hidden",msOverflowStyle:"none",scrollbarWidth:"none"}} > 
        <table>
          <tr>
            <th style={{whiteSpace: "nowrap"}}>&nbsp;&nbsp;Mark Rubrics</th>
          </tr>
          <tr>
            <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>Technique</td>
            <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>Musicality</td>
            <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>Style</td>
            <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>Tone Color</td>
            <td style={{border:"1px solid black",textAlign:"center",borderTop:"1px solid white"}}>Presentation</td>
          </tr>
        </table>
        </div>
        
        <br></br>

 
      <table style={{maxWidth:"100%",padding:"0% 1%"}}>
      <tr>
          <td>Mark Range</td>
          <td>Class</td>
      </tr>
      <tr>
          <td>&gt;&nbsp;90 </td>
          <td>Winners or Gold Award</td>
      </tr>
      <tr>
          <td>85-90</td>
          <td>Winners or Gold Award</td>
      </tr>
      <tr>
          <td>75-85 </td>
          <td>Silver Award</td>
      </tr>
      <tr>
          <td>65-75</td>
          <td>Bronze Award </td>
      </tr>
      <tr>
          <td>&lt;&nbsp;65</td>
          <td>Certificate</td>
      </tr>
    
  </table>

      {<div style={{marginTop:"6%",fontFamily:"Courgette",fontSize:"1em"}}>
        <p><sup>1</sup><i>For trophies and paper certificates, please fill in the Award Order Form within 7 days via email after receiving your results.</i></p>
      </div>}
      

      {/* <h1 style={{marginTop:"7%",marginBottom:"1vh"}}>Champion List</h1>
                <br></br>

      <div style={{justifyContent:"center",display:"flex",flexDirection:"column",fontSize:"1.3rem"}}>
      <table className="award-laureate-table" >
      {
        Object.keys(laureate).map((key,item)=>{
          return(  
                <tr style={{border:"1px solid white"}}>
                  <td style={{textAlign:"center",borderRight:"1px solid white"}}>{key}</td>
                  <td style={{textAlign:"center"}}>{laureate[key]}</td>
                </tr>    
          )
        })
      }
      </table>
      </div> */}
      

      
      </div>
      </div>
    );
  }
  }
  

export default Awards;
