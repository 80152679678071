import React from 'react'
import { Navbar,Nav,NavDropdown,Form,FormControl,Button } from 'react-bootstrap'

const applyurl = "https://docs.google.com/forms/d/1JQvox_w4JTU0rvRcnRxPnfGxWmQBuq5V0LoMmVBY9Gs/"
//import "../../public/assets/fb48.svg"

class IESADCard extends React.Component{
    constructor(props){
        super(props);
        this.state = {  
          
        };
    
        this.openlink = this.openlink.bind(this)
      }
    
      openlink(link){
        window.open(link)
      }
    render(){
        return(
            <div className="home-card">
                {/* <div className="cardphoto">
                    {(this.props.index===1) && <img src="/assets/home-card-1.jpg" width="100%" height="100%"  />}
                    {(this.props.index===2) && <img src="/assets/home-card-2.jpg" width="100%" height="100%"  />}
                    {(this.props.index===3) && <img src="/assets/home-card-3.jpg" width="100%" height="100%"  />}
                </div> */}

                <div className="cardhead">
                    <h2 className="about-title">{this.props.title}</h2>
                    <p style={{color:"white",fontSize:"1.5em",fontFamily:"Courgette"}}>{this.props.tagline}</p>
                </div>

                <div className="cardcontent">
                {
                    this.props.content.split('\n').map(
                    (paragraph) => {
                    return <p className="about-text">{paragraph}</p>;
                    })
                }
                 <div>&nbsp;</div>
                 <div style={{display:"flex",flexDirection:"column",marginLeft:"20%",marginRight:"20%"}}>
                <a href="/iesad/apply"><button className="about-redbutton" style={{width:"100%"}} onClick={(e)=>{
                    //window.open(applyurl, '_blank', 'noopener,noreferrer');
                    //this.props.setval({pageSelected:"apply"})
                }}>APPLY NOW</button></a>
                </div>
                </div>

                 
                
                <div className={"cardhead"}>{"Other Competitions by the International Culture Fellowship"}</div>
                <div className={"about-poster-wrapper"} >
                    
                    <div className={"about-poster-item"} onClick={(e)=>{
                        window.open("http://youngartistcompetition.org/iyao/", '_blank').focus();
                    }}>
                        <img src={"../assets/iyao-poster-2024.PNG"} width="100%" />
                    </div>
                    <div className={"about-poster-item"} onClick={(e)=>{
                        window.open("http://youngartistcompetition.org/iyfao/", '_blank').focus();
                    }}>
                        <img src={"../assets/iyfao-poster-2024.png"} width="100%" />
                    </div>
                    <div className={"about-poster-item"} onClick={(e)=>{
                        window.open("http://artandmusictalent.org/icmt/", '_blank').focus();
                    }}>
                        <img src={"../assets/icmt-poster-2024.PNG"} width="100%" />
                    </div>
                    <div className={"about-poster-item"} onClick={(e)=>{
                        window.open("http://artandmusictalent.org/iypo/", '_blank').focus();
                    }}>
                        <img src={"../assets/iypc-poster-2024.png"} width="100%" />
                    </div>
                </div>

                {/* <div className={"about-smalltext"}>{"International Culture Fellowship Ltd is a private company incorporated in HK."}</div> */}



            </div>
        )  
    }
}

export default IESADCard;